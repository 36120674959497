<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/sources/${$route.params.id}`" exact exact-active-class="active">Source</b-nav-item>
            <b-nav-item :to="`/sources/${$route.params.id}/events`" exact exact-active-class="active">Events</b-nav-item>
        </b-nav>
    </b-card-header>
    <b-card-body v-if="source">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                    <b-form-input v-model="source.id" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" :state="validField('label')" invalid-feedback="Please provide a valid label.">
                    <b-form-input v-model="source.label" :state="validField('label')" :readonly="!canEdit()"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Attribute" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="source.attribute" readonly></b-form-input>
                        <b-input-group-append>
                            <b-button :to="`/attributes/${source.attribute}`" variant="outline-primary">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Factor" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="source.factor" readonly></b-form-input>
                        <b-input-group-append>
                            <b-button :to="`/factors/${source.factor}`" variant="outline-primary">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Claim" label-align-sm="right" label-cols-sm="3">
                    <b-form-input v-model="source.claim" disabled></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                    <b-form-select v-model="source.status" :options="canEdit() ? statuses.filter(status => ['ENABLED','DISABLED'].includes(status.value)) : statuses" :disabled="!canEdit()"></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body v-if="source?.config">
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
            </b-card-header>
            <b-collapse id="configuration" visible>
                <b-card-body>
                    <b-row>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Bidirectional" label-align-sm="right" label-cols-sm="3" description="This indicates whether the source will also work in reverse, i.e. create a factor from a new attribute.">
                                <b-form-checkbox v-model="source.config.bidirectional" switch :disabled="!canEdit() || source.claim !== 'input'"></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </b-card>
    </b-card-body>
    <b-card-body v-if="source">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="source.created_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="source.created_by" readonly></b-form-input>
                        <b-badge v-if="source.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${source.created_by}`" variant="outline-primary" :disabled="source.created_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="source.updated_at">
                <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="source.updated_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="source.updated_by">
                <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="source.updated_by" readonly></b-form-input>
                        <b-badge v-if="source.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${source.updated_by}`" variant="outline-primary" :disabled="source.updated_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="source.expires_at">
                <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="source.expires_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="success" v-on:click="saveSource()" :disabled="!canEdit() || !validSource()">Save</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="$emit('load', 'source')">Refresh</b-button>
        <b-button variant="outline-danger" class="ml-auto" v-on:click="$emit('show', 'delete-source', source)" :disabled="!canEdit()">Delete</b-button>
    </b-card-body>
    <b-card-footer v-if="source?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ source.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
 const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const SUBTYPES = [
    { value: 'string', text: 'String' },
    { value: 'number', text: 'Number' },
    { value: 'boolean', text: 'Boolean' },
    { value: 'url', text: 'URL' },
    { value: 'string:oidc1:name', text: 'Name' },
    { value: 'string:oidc1:given_name', text: 'Given Name' },
    { value: 'string:oidc1:family_name', text: 'Family Name' },
    { value: 'string:oidc1:middle_name', text: 'Middle Name' },
    { value: 'string:oidc1:nickname', text: 'Nickname' },
    { value: 'string:oidc1:preferred_username', text: 'Preferred Username' },
    { value: 'url:oidc1:profile', text: 'Profile' },
    { value: 'url:oidc1:picture', text: 'Picture' },
    { value: 'url:oidc1:website', text: 'Website' },
    { value: 'string:oidc1:email', text: 'Email' },
    { value: 'string:oidc1:gender', text: 'Gender' },
    { value: 'string:oidc1:birthdate', text: 'Birthdate' },
    { value: 'string:oidc1:zoneinfo', text: 'Time Zone' },
    { value: 'string:oidc1:phone_number', text: 'Phone Number' },
    { value: 'string:oidc1:address:formatted', text: 'Formatted Address' },
    { value: 'string:oidc1:address:street_address', text: 'Street Address' },
    { value: 'string:oidc1:address:locality', text: 'Locality' },
    { value: 'string:oidc1:address:region', text: 'Region' },
    { value: 'string:oidc1:address:postal_code', text: 'Postal Code' },
    { value: 'string:oidc1:address:country', text: 'Country' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Source',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'loading', 'load', 'next', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        source: Object
    },

    /**
     * DATA
     */
    data() {
        return {
            // STATUSES
            statuses: STATUSES,
            // SUBTYPES
            subtypes: SUBTYPES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * METHODS
     */
    methods: {

        /**
         * SOURCE
         */
        async saveSource() {
            this.$emit('loading', 'Saving');
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateSource($input: UpdateSourceInput!) {
                                updateSource(input: $input) {
                                    id
                                    label
                                    claim
                                    attribute
                                    factor
                                    status
                                    config {
                                        bidirectional
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.source.id}",
                                "claim": "${this.source.claim}",
                                "label": "${this.source.label}",
                                "status": "${this.source.status}",
                                "config": {
                                    "bidirectional": ${this.source.config.bidirectional}
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const source = (await response.json()).data.updateSource;
                    this.$emit('load', 'source');
                    this.$emit('alert', 'Your source has been updated', 'Source', 'success', 5000);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save source.', 'Source', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save source.', 'Source', 'danger');
            }
            this.$emit('loading', undefined);
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED','DISABLED'].includes(this.source?.status);
        },
        
        validSource() {
            if (!this.validField('label')) return false;
            return true;
        },

        validField(field) {
            switch (field) {
                case 'label':
                    return !!this.source.label;
                default:
                    return false;
            }
        }
    }
}
</script>