<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/attributes.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Attribute</h3>
            </b-card-header>
            <b-card-body v-if="claim">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="claim.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="claim.account" readonly></b-form-input>
                                <b-badge v-if="claim.account === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${claim.account}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="claim.label" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="claim.subtype" :options="subtypes" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="$store.state.attributes?.items">
                        <b-form-group label="Attribute" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-select v-model="claim.attribute" :options="$store.state.attributes.items" value-field="id" text-field="label" disabled></b-form-select>
                                <b-input-group-append>
                                    <b-button :to="`/attributes/${claim.attribute}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Value" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="claim.value" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="claim.status" :options="statuses" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="claim.source">
                        <b-form-group label="Source" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="claim.source" readonly></b-form-input>
                                <b-input-group-append>
                                    <b-button :to="`/enrollments/${claim.source}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="claim">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="claim.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="claim.created_by" readonly></b-form-input>
                                <b-badge v-if="claim.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${claim.created_by}`" variant="outline-primary" :disabled="claim.created_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="claim.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="claim.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="claim.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="claim.updated_by" readonly></b-form-input>
                                <b-badge v-if="claim.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${claim.updated_by}`" variant="outline-primary" :disabled="claim.updated_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="claim.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="claim.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="getClaim()">Refresh</b-button>
                <b-button v-if="claim?.status === 'ENABLED'" variant="outline-danger" class="ml-auto" v-on:click="disableClaim()">Disable</b-button>
                <b-button :class="claim?.status === 'ENABLED' ? 'ml-2' : 'ml-auto'" variant="outline-danger" v-on:click="$emit('show', 'delete-claim', claim)" :disabled="!canDelete()">Delete</b-button>
            </b-card-body>
            <b-card-footer v-if="claim?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ claim.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const SUBTYPES = [
    { value: 'string', text: 'String' },
    { value: 'number', text: 'Number' },
    { value: 'boolean', text: 'Boolean' },
    { value: 'url', text: 'URL' },
    { value: 'string:oidc1:name', text: 'Name' },
    { value: 'string:oidc1:given_name', text: 'Given Name' },
    { value: 'string:oidc1:family_name', text: 'Family Name' },
    { value: 'string:oidc1:middle_name', text: 'Middle Name' },
    { value: 'string:oidc1:nickname', text: 'Nickname' },
    { value: 'string:oidc1:preferred_username', text: 'Preferred Username' },
    { value: 'url:oidc1:profile', text: 'Profile' },
    { value: 'url:oidc1:picture', text: 'Picture' },
    { value: 'url:oidc1:website', text: 'Website' },
    { value: 'string:oidc1:email', text: 'Email' },
    { value: 'string:oidc1:gender', text: 'Gender' },
    { value: 'string:oidc1:birthdate', text: 'Birthdate' },
    { value: 'string:oidc1:zoneinfo', text: 'Time Zone' },
    { value: 'string:oidc1:phone_number', text: 'Phone Number' },
    { value: 'string:oidc1:address:formatted', text: 'Formatted Address' },
    { value: 'string:oidc1:address:street_address', text: 'Street Address' },
    { value: 'string:oidc1:address:locality', text: 'Locality' },
    { value: 'string:oidc1:address:region', text: 'Region' },
    { value: 'string:oidc1:address:postal_code', text: 'Postal Code' },
    { value: 'string:oidc1:address:country', text: 'Country' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Claim',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // CLAIM
            claim: undefined,
            // STATUSES
            statuses: STATUSES,
            // SUBTYPES
            subtypes: SUBTYPES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.claim) {
            this.getClaim();
        }
        if (!this.$store.state.attributes) {
            this.$emit('load', 'attributes', true); // LOAD ALL
        } else if (this.$store.state.attributes.nextToken) {
            this.$emit('next', 'attributes', true); // LOAD ALL
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * CLAIM
         */
        async getClaim() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getClaim($id: ID!) {
                                getClaim(id: $id) {
                                    id
                                    label
                                    attribute
                                    account
                                    value
                                    source
                                    status
                                    subtype
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.claim = (await response.json()).data.getClaim;
                    // SET DATES
                    this.claim.created_at = this.claim.created_at ? new Date(this.claim.created_at) : undefined;
                    this.claim.updated_at = this.claim.updated_at ? new Date(this.claim.updated_at) : undefined;
                    this.claim.expires_at = this.claim.expires_at ? new Date(this.claim.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.claim.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain attribute.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain attribute.', 'Attribute', 'danger');
            }
            this.loading = undefined;
        },

        async disableClaim() {
            this.loading = 'Disabling';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateClaim($input: UpdateClaimInput!) {
                                updateClaim(input: $input) {
                                    id
                                    label
                                    attribute
                                    account
                                    value
                                    source
                                    status
                                    subtype
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.claim.id}",
                                "status": "DISABLED"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.claim = (await response.json()).data.updateClaim;
                    // SET DATES
                    this.claim.created_at = this.claim.created_at ? new Date(this.claim.created_at) : undefined;
                    this.claim.updated_at = this.claim.updated_at ? new Date(this.claim.updated_at) : undefined;
                    this.claim.expires_at = this.claim.expires_at ? new Date(this.claim.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.claim.refreshed_at = new Date();
                    this.$emit('alert', 'The attribute has been disabled.', 'Attribute', 'success', 5000);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to disable attribute.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to disable attribute.', 'Attribute', 'danger');
            }
            this.loading = undefined;
        },

        /**
         * VALIDATION
         */
        canDelete() {
            return this.claim?.status !== 'LOCKED';
        }
    }
}
</script>