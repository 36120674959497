<template>
    <b-overlay :show="loading || loading2">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading || loading2 }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/controls.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Control</h3>
            </b-card-header>
            <RouterView v-slot="{ Component }">
                <component :is="Component" :filter="filter" :variant="variant" :control="control" :consents="consents" :permissions="permissions" @alert="sendAlert" @login="sendLogin" @loading="setLoading" @load="sendLoad" @next="sendNext" @show="sendShow" :loading_more="loading_more" @disable="disableData" @enable="enableData"/>
            </RouterView>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'ControlCard',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // CONSENTS
            consents: undefined,
            // PERMISSIONS
            permissions: undefined,
            // LOADING
            loading2: undefined,
            // CONTROL
            control: undefined,
            // LOADING (MORE)
            loading_more: false
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
     async created() {
        if (!this.control) {
            this.getControl();
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * LOADING
         */
        setLoading(loading) {
            this.loading2 = loading;
        },

        /**
         * EVENTS
         */
        sendAlert(...args) {
            this.$emit('alert', ...args);
        },

        sendLogin(...args) {
            this.$emit('login', ...args);
        },

        sendLoad(...args) {
            if (args[0] === 'control') {
                this.getControl();
            } else if (args[0] === 'consents') {
                this.listConsents();
            } else if (args[0] === 'permissions') {
                this.listPermissions();
            } else {
                this.$emit('load', ...args);
            }
        },

        sendNext(...args) {
            if (args[0] === 'consents') {
                this.nextConsents();
            } else if (args[0] === 'permissions') {
                    this.nextPermissions();
            } else {
                this.$emit('next', ...args);
            }
        },

        sendShow(...args) {
            this.$emit('show', ...args);
        },

        /**
         * ACTIONS
         */
        async disableData(resource, id) {
            this.loading2 = 'Disabling';
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation update${resource.charAt(0).toUpperCase() + resource.slice(1)}($input: Update${resource.charAt(0).toUpperCase() + resource.slice(1)}Input!) {
                                update${resource.charAt(0).toUpperCase() + resource.slice(1)}(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${id}",
                                "status": "DISABLED"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    (await response.json()).data[`update${resource.charAt(0).toUpperCase() + resource.slice(1)}`];
                    this.$emit('alert', `The ${resource} has been disabled.`, 'Control', 'success', 5000);
                    this.sendLoad(`${resource}s`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', `Failed to disable ${resource}.`, 'Control', 'danger');
                }

            } catch (error) {
                this.$emit('alert', `Failed to disable ${resource}.`, 'Control', 'danger');
            }
            this.loading2 = undefined;
        },

        async enableData(resource, id) {
            this.loading2 = 'Enabling';
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation update${resource.charAt(0).toUpperCase() + resource.slice(1)}($input: Update${resource.charAt(0).toUpperCase() + resource.slice(1)}Input!) {
                                update${resource.charAt(0).toUpperCase() + resource.slice(1)}(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${id}",
                                "status": "ENABLED"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    (await response.json()).data[`update${resource.charAt(0).toUpperCase() + resource.slice(1)}`];
                    this.$emit('alert', `The ${resource} has been enabled.`, 'Control', 'success', 5000);
                    this.sendLoad(`${resource}s`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', `Failed to enable ${resource}.`, 'Control', 'danger');
                }

            } catch (error) {
                this.$emit('alert', `Failed to enable ${resource}.`, 'Control', 'danger');
            }
            this.loading2 = undefined;
        },

        /**
         * CONTROL
         */
        async getControl() {
            this.loading2 = 'Loading';
            try {

                // GET FACTOR
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getControl($id: ID!) {
                                getControl(id: $id) {
                                    id
                                    label
                                    value
                                    score
                                    status
                                    subtype
                                    config {
                                        consent_required
                                        permission_required
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.control = (await response.json()).data.getControl;
                    // SET DATES
                    this.control.created_at = this.control.created_at ? new Date(this.control.created_at) : undefined;
                    this.control.updated_at = this.control.updated_at ? new Date(this.control.updated_at) : undefined;
                    this.control.expires_at = this.control.expires_at ? new Date(this.control.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.control.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain control.', 'Control', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain control.', 'Control', 'danger');
            }
            this.loading2 = undefined;
        },

        /**
         * CONSENTS
         */
        async listConsents() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listConsents($filter: TableConsentFilterInput, $limit: Int) {
                                listConsents(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        account
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "control": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.consents = (await response.json()).data.listConsents;
                    // ADD REFRESH DATE
                    this.consents.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.consents.nextToken) {
                        this.$emit('alert', 'More consents are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Control', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain consents.', 'Control', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain consents.', 'Control', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextConsents() {
            if (!this.consents.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listConsents($filter: TableConsentFilterInput, $limit: Int, $nextToken: String) {
                                listConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        account
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "control": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.consents.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const consents = (await response.json()).data.listConsents;
                    // ADD NEW CONSENTS
                    for (const consent of consents.items) {
                        this.consents.items.push(consent);
                    }
                    // SET NEXT TOKEN
                    this.consents.nextToken = consents.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (consents.nextToken) {
                        this.$emit('alert', 'More consents are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Control', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain consents.', 'Control', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain consents.', 'Control', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * PERMISSIONS
         */
        async listPermissions() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listPermissions($filter: TablePermissionFilterInput, $limit: Int) {
                                listPermissions(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        account
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "control": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.permissions = (await response.json()).data.listPermissions;
                    // ADD REFRESH DATE
                    this.permissions.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.permissions.nextToken) {
                        this.$emit('alert', 'More permissions are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Control', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain permissions.', 'Control', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain permissions.', 'Control', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextPermissions() {
            if (!this.permissions.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listPermissions($filter: TablePermissionFilterInput, $limit: Int, $nextToken: String) {
                                listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        account
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "control": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.permissions.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const permissions = (await response.json()).data.listPermissions;
                    // ADD NEW PERMISSIONS
                    for (const permission of permissions.items) {
                        this.permissions.items.push(permission);
                    }
                    // SET NEXT TOKEN
                    this.permissions.nextToken = permissions.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (permissions.nextToken) {
                        this.$emit('alert', 'More permissions are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Control', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain permissions.', 'Control', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain permissions.', 'Control', 'danger');
            }
            this.loading_more = undefined;
        }
    }
}
</script>