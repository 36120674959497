<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/sources/${$route.params.id}`" exact exact-active-class="active">Source</b-nav-item>
            <b-nav-item :to="`/sources/${$route.params.id}/events`" exact exact-active-class="active">Events</b-nav-item>
        </b-nav>
        <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
    </b-card-header>
    <b-card-header class="text-muted bg-light">
        <small>Events are currently processed every 15 minutes.</small>
    </b-card-header>
    <b-card-body v-if="events">
        <b-table id="table" outlined hover :items="events.items" :fields="fields" primary-key="id" sort-by="time" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/events/${item.id}`)" class="mb-0" show-empty empty-text="This source has no events yet. It can take up to 15 minutes for events to show up." empty-filtered-text="No events match your search. If more events are available you can load them by clicking 'Load More' below.">
            <template #cell(account_id)="data">
                <b-link :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
            </template>
            <template #cell(result)="data">
                <b-button :variant="variant(data.value)" size="sm" disabled>{{ results[data.value] }}</b-button>
            </template>
        </b-table>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="$emit('load', 'events')">Refresh</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
        <b-button v-if="events" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? events.items.length) - page * 10, 0)">Next</b-button>
        <b-button v-if="events?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'events')" class="ml-2" :disabled="loading_more">Load More</b-button>
    </b-card-body>
    <b-card-footer v-if="events?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ events.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'time',
        label: 'Time',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'type',
        label: 'Type',
        sortable: true,
        formatter: value => TYPES[value]
    },
    {
        key: 'account_id',
        label: 'Account',
        sortable: true
    },
    {
        key: 'action',
        label: 'Action',
        sortable: true
    },
    {
        key: 'result',
        label: 'Result',
        sortable: true
    }
];
const RESULTS = {
    'PENDING': 'Pending',
    'SUCCESS': 'Success',
    'FAILED': 'Failed'
};
const TYPES = {
    'API': 'API',
    'DATA': 'Data',
    'DATABASE': 'Database',
    'COMMUNICATION': 'Communication',
    'AUTHENTICATION': 'Authentication',
    'AUTHORIZATION': 'Authorization'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'SourceEvents',

    /**
     * EVENTS
     */
     emits: [ 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading_more: String,
        events: Array
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // RESULTS
            results: RESULTS,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.events) {
            this.$emit('load', 'events', false);
        }
    }
}
</script>